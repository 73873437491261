const values = [
  { id: 1, title: 'Acceptance', description: 'to be accepted as I am.' },
  { id: 2, title: 'Accuracy', description: 'to be correct in my opinions and beliefs.' },
  { id: 3, title: 'Achievement', description: 'to have important accomplishments.' },
  { id: 4, title: 'Adventure', description: 'to have new and exciting experiences.' },
  { id: 5, title: 'Art', description: 'to appreciate or express myself in art.' },
  { id: 6, title: 'Attractiveness', description: 'to be physically attractive.' },
  { id: 7, title: 'Authority', description: 'to be in charge of others.' },
  { id: 8, title: 'Autonomy', description: 'to be self-determined and independent.' },
  { id: 9, title: 'Beauty', description: 'to appreciate beauty around me.' },
  { id: 10, title: 'Belonging', description: 'to have a sense of belonging, being part of.' },
  { id: 11, title: 'Caring', description: 'to take care of others.' },
  { id: 12, title: 'Challenge', description: 'to take on difficult tasks and problems.' },
  { id: 13, title: 'Comfort', description: 'to have a pleasant and comfortable life.' },
  { id: 14, title: 'Commitment', description: 'to make enduring, meaningful commitments.' },
  { id: 15, title: 'Compassion', description: 'to feel and act on concern for others.' },
  { id: 16, title: 'Complexity', description: 'to embrace the intricacies of life.' },
  { id: 17, title: 'Compromise', description: 'to be willing to give and take in reaching agreements.' },
  { id: 18, title: 'Contribution', description: 'to make a lasting contribution in the world.' },
  { id: 19, title: 'Cooperation', description: 'to work collaboratively with others.' },
  { id: 20, title: 'Courage', description: 'to be brave and strong in the face of adversity.' },
  { id: 21, title: 'Courtesy', description: 'to be considerate and polite toward others.' },
  { id: 22, title: 'Creativity', description: 'to create new things or ideas.' },
  { id: 23, title: 'Curiosity', description: 'to seek out, experience, and learn new things.' },
  { id: 24, title: 'Dependability', description: 'to be reliable and trustworthy.' },
  { id: 25, title: 'Diligence', description: 'to be thorough and conscientious in whatever I do.' },
  { id: 26, title: 'Duty', description: 'to carry out my duties and obligations.' },
  { id: 27, title: 'Ecology', description: 'to live in harmony with the environment.' },
  { id: 28, title: 'Excitement', description: 'to have a life full of thrills and stimulation.' },
  { id: 29, title: 'Faithfulness', description: 'to be loyal and true in relationships.' },
  { id: 30, title: 'Fame', description: 'to be known and recognized.' },
  { id: 31, title: 'Family', description: 'to have a happy, loving family.' },
  { id: 32, title: 'Fitness', description: 'to be physically fit and strong.' },
  { id: 33, title: 'Flexibility', description: 'to adjust to new circumstances easily.' },
  { id: 34, title: 'Forgiveness', description: 'to be forgiving of others.' },
  { id: 35, title: 'Freedom', description: 'to be free from undue restrictions and limitations.' },
  { id: 36, title: 'Friendship', description: 'to have close, supportive friends.' },
  { id: 37, title: 'Fun', description: 'to play and have fun.' },
  { id: 38, title: 'Generosity', description: 'to give what I have to others.' },
  { id: 39, title: 'Genuineness', description: 'to act in a manner that is true to who I am.' },
  { id: 40, title: 'God’s Will', description: 'to seek and obey the will of God.' },
  { id: 41, title: 'Gratitude', description: 'to be thankful and appreciative.' },
  { id: 42, title: 'Growth', description: 'to keep changing and growing.' },
  { id: 43, title: 'Health', description: 'to be physically well and healthy.' },
  { id: 44, title: 'Honesty', description: 'to be honest and truthful.' },
  { id: 45, title: 'Hope', description: 'to maintain a positive and optimistic outlook.' },
  { id: 46, title: 'Humility', description: 'to be modest and unassuming.' },
  { id: 47, title: 'Humor', description: 'to see the humorous side of myself and the world.' },
  { id: 48, title: 'Imagination', description: 'to have dreams and see possibilities.' },
  { id: 49, title: 'Independence', description: 'to be free from depending on others.' },
  { id: 50, title: 'Industry', description: 'to work hard and well at my life tasks.' },
  { id: 51, title: 'Inner Peace', description: 'to experience personal peace.' },
  { id: 52, title: 'Integrity', description: 'to live my daily life in a way that is consistent with my values.' },
  { id: 53, title: 'Intelligence', description: 'to keep my mind sharp and active.' },
  { id: 54, title: 'Intimacy', description: 'to share my innermost experiences with others.' },
  { id: 55, title: 'Justice', description: 'to promote fair and equal treatment for all.' },
  { id: 56, title: 'Knowledge', description: 'to learn and contribute valuable knowledge.' },
  { id: 57, title: 'Leadership', description: 'to inspire and guide others.' },
  { id: 58, title: 'Leisure', description: 'to take time to relax and enjoy.' },
  { id: 59, title: 'Loving', description: 'to give love to others.' },
  { id: 60, title: 'Loved', description: 'to be loved by those close to me.' },
  { id: 61, title: 'Mastery', description: 'to be competent in my everyday activities.' },
  { id: 62, title: 'Mindfulness', description: 'to live conscious and mindful of the present moment.' },
  { id: 63, title: 'Moderation', description: 'to avoid excesses and find a middle ground.' },
  { id: 64, title: 'Monogamy', description: 'to have one close, loving relationship.' },
  { id: 65, title: 'Music', description: 'to enjoy or express myself in music.' },
  { id: 66, title: 'Non-Conformity', description: 'to question and challenge authority and norms.' },
  { id: 67, title: 'Novelty', description: 'to have a life full of change and variety.' },
  { id: 68, title: 'Nurturance', description: 'to encourage and support others.' },
  { id: 69, title: 'Openness', description: 'to be open to new experiences, ideas, and options.' },
  { id: 70, title: 'Order', description: 'to have a life that is well-ordered and organized.' },
  { id: 71, title: 'Passion', description: 'to have deep feelings about ideas, activities, or people.' },
  { id: 72, title: 'Patriotism', description: 'to love, serve, and protect my country.' },
  { id: 73, title: 'Pleasure', description: 'to feel good.' },
  { id: 74, title: 'Popularity', description: 'to be well-liked by many people.' },
  { id: 75, title: 'Power', description: 'to have control over others.' },
  { id: 76, title: 'Practicality', description: 'to focus on what is practical, prudent, and sensible.' },
  { id: 77, title: 'Protect', description: 'to protect and keep safe those I love.' },
  { id: 78, title: 'Provide', description: 'to provide for and take care of my family.' },
  { id: 79, title: 'Purpose', description: 'to have meaning and direction in my life.' },
  { id: 80, title: 'Rationality', description: 'to be guided by reason, logic, and evidence.' },
  { id: 81, title: 'Realism', description: 'to see and act realistically and practically.' },
  { id: 82, title: 'Responsibility', description: 'to make and carry out responsible decisions.' },
  { id: 83, title: 'Risk', description: 'to take risks and chances.' },
  { id: 84, title: 'Romance', description: 'to have intense, exciting love in my life.' },
  { id: 85, title: 'Safety', description: 'to be safe and secure.' },
  { id: 86, title: 'Self-Acceptance', description: 'to accept myself as I am.' },
  { id: 87, title: 'Self-Control', description: 'to be disciplined in my own actions.' },
  { id: 88, title: 'Self-Esteem', description: 'to feel good about myself.' },
  { id: 89, title: 'Self-Knowledge', description: 'to have a deep and honest understanding of myself.' },
  { id: 90, title: 'Service', description: 'to be helpful and of service to others.' },
  { id: 91, title: 'Sexuality', description: 'to have an active and satisfying sex life.' },
  { id: 92, title: 'Simplicity', description: 'to live life simply, with minimal needs.' },
  { id: 93, title: 'Solitude', description: 'to have time and space where I can be apart from others.' },
  { id: 94, title: 'Spirituality', description: 'to grow and mature spiritually.' },
  { id: 95, title: 'Stability', description: 'to have a life that stays fairly consistent.' },
  { id: 96, title: 'Tolerance', description: 'to accept and respect those who differ from me.' },
  { id: 97, title: 'Tradition', description: 'to follow respected patterns of the past.' },
  { id: 98, title: 'Virtue', description: 'to live a morally pure and excellent life.' },
  { id: 99, title: 'Wealth', description: 'to have plenty of money.' },
  { id: 100, title: 'World Peace', description: 'to work to promote peace in the world.' }
];

export default values;
